var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "event-thumbnail",
      attrs: { "data-testid": "event-thumbnail" },
      on: {
        click: function (event) {
          return _vm.$emit("click", event)
        },
      },
    },
    [
      _c(
        "div",
        [_c("event-detail-header", { attrs: { event: _vm.event } })],
        1
      ),
      _vm._v(" "),
      _c("loading-image", {
        attrs: {
          forceLoading: _vm.event.updating,
          src: _vm.event.attachments.thumbnail,
        },
        on: {
          loaded: function ($event) {
            return _vm.$emit("loaded")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }