<template>
  <div class="event-thumbnail" @click="event => $emit('click', event)" data-testid="event-thumbnail">
    <div>
      <event-detail-header :event="event"/>
    </div>
    <loading-image
      :forceLoading="event.updating"
      :src="event.attachments.thumbnail"
      @loaded="$emit('loaded')"
    />
  </div>
</template>

<script>
import EventDetailHeader from '@/components/EventDetailHeader';
import LoadingImage from '@/components/LoadingImage';

export default {
  name: 'event-thumbnail',
  props: {
    event: Object,
  },
  components: {
    LoadingImage,
    EventDetailHeader,
  },
};
</script>

<style scoped lang="sass">
.event-thumbnail
  cursor: pointer
  display: flex
  flex-direction: column

  ::v-deep .loading-image
    flex-grow: 1
    // Allow shrinking below real size
    min-height: 0

  ::v-deep img
    // For too wide images
    object-fit: cover
</style>
