<template>
  <div v-if="visible" data-testid="sensor_events">
    <v-divider class="my-4"/>
    <content-header
      titleClass="title mr-2"
      :description="sensorDetails"
      :subtitle="sensor.place"
      :to="`sensors/${sensor.id}`"
      subtitleClass="subheading"
      class="my-3"
    >
      <template #title>{{ sensor.name }}</template>
      <template #icon><sensor-icon :sensor="sensor" /></template>
      <v-row no-gutters>
        <v-spacer/>
        <rat-graph-button
          :sensor="sensor"
          :requestParams="exportParams"
          class="hidden-sm-and-down"
          v-if="sensor.type === SENSOR_TYPES.RAT"
        />
        <sensor-events-export-button
          :sensor="sensor"
          :requestParams="exportParams"
          class="ml-2 hidden-sm-and-down"
        />
      </v-row>
    </content-header>
    <div class="mb-4">
      <round-button
        class="expand-btn"
        :icon="expanded ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'"
        @click="$emit('update:expanded', !expanded)"
        v-if="detectedCount > 0"
      >
        {{ $t('button.expand') }}
      </round-button>
    </div>
    <v-row wrap>
      <template v-for="event in displayedEvents">
        <v-flex md3 xs12 class="pa-2 event-wrapper">
          <event
            class="event"
            :key="event.id"
            :event="event"
            :editable="isEditable"
            @event:selected="$emit('event:selected', event, events)"
            @event:hidden="v => updateEventHidden(event, v)"
            />
        </v-flex>
      </template>
    </v-row>
    <div class="mt-4 bottom-buttons">
      <div class="d-flex justify-content-between" ref="listEnd">
        <v-progress-linear
          color="primary"
          indeterminate
          rounded
          height="6"
          v-if="eventsLoading"
        />
      </div>
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  button:
    expand: 'センサーを展開'
    export: 'CSV出力'

  label:
    installation_date: '設置日 : {date}'
    threshold: 'しきい値 :  {value}'

en:
  button:
    expand: 'Expand'
    export: 'Export CSV'

  label:
    installation_date: 'Installation date: {date}'
    threshold: 'Threshold: {value}'
</i18n>

<script>
import EventContainer from '@/mixins/eventContainer';
import Platform from '@/mixins/platform';

import SensorTypes from '@/mixins/sensorTypes';
import RoundButton from '@/components/atoms/RoundButton';
import ContentHeader from '@/components/ContentHeader';
import SensorIcon from '@/components/SensorIcon';
import SensorEventsExportButton from './SensorEventsExportButton';
import RatGraphButton from './RatGraphButton';
import Event from './Event';

export default {
  name: 'sensor-events',
  mixins: [EventContainer, Platform, SensorTypes],
  props: {
    sensor: Object,
    requestParams: {
      type: Object,
      required: true,
    },
    expanded: Boolean,
  },
  data() {
    return {
      displayedCount: 4,
      scrollListener: this._.throttle(this.showNewElementsIfNecessary, 1000 / 60),
    };
  },
  components: {
    ContentHeader,
    RoundButton,
    SensorIcon,
    SensorEventsExportButton,
    RatGraphButton,
    Event,
  },
  computed: {
    detectedCount() {
      return this.events.filter(ev => ev.detected).length;
    },
    displayedEvents() {
      return this._.take(this.events, this.displayedCount);
    },
    exportParams() {
      return {
        ...this.requestParams,
        with_undetected: 1,
      };
    },
    isEditable() {
      return this.sensor.permissions.includes('full');
    },
    onEventUpdate() {
      return this.$store.getters.getUpdatedEvent(this.sensorId);
    },
    sensorDetails() {
      let details = '';

      if (this.sensor.installed_on) {
        const installedOn = this.$moment(this.sensor.installed_on).format('YYYY.M.D');
        details += this.$t('label.installation_date', { date: installedOn });
      }

      if (this.sensor.threshold) {
        if (this.sensor.installed_on) {
          details += ' / ';
        }
        details += this.$t('label.threshold', { value: this.sensor.threshold });
      }

      return details;
    },
    sensorId() {
      return this.sensor.id;
    },
    shouldReload() {
      return this.$store.getters.shouldReloadSensor(this.sensorId);
    },
    totalCount() {
      return this.events.length;
    },
    visible() {
      return this.totalCount > 0;
    },
  },
  methods: {
    isListEndInViewport() {
      const rect = this.$refs.listEnd.getBoundingClientRect();
      const html = document.documentElement;
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) &&
        rect.right <= (window.innerWidth || html.clientWidth)
      );
    },
    showNewElementsIfNecessary: async function () {
      if (this.expanded && this.isListEndInViewport()) {
        if (this.displayedCount < this.totalCount) {
          this.displayedCount = Math.min(this.displayedCount + (this.isSmartphone() ? 1 : 4),
                                         this.totalCount);
          await this.$nextTick();
          await this.showNewElementsIfNecessary();
        } else if (!this.allEventsLoaded && !this.eventsLoading) {
          await this.loadNextEvents(40);
          await this.showNewElementsIfNecessary();
        }
      }
    },
    updateEventHidden: async function (event, value) {
      event.hidden = value;
      await this.$store.dispatch('updateSensorEvent', {
        event,
        attributes: { hidden: value },
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener);
  },
  watch: {
    expanded(value) {
      if (value) {
        this.showNewElementsIfNecessary();
      } else {
        this.displayedCount = 4;
      }
    },
    onEventUpdate(updated) {
      if (updated) {
        const index = this.events.findIndex(ev => ev.id === updated.id);
        if (index !== -1) {
          this.events.splice(index, 1, updated);
        }
      }
    },
    shouldReload: async function (value) {
      if (value) {
        this.clearEvents();
        await this.loadNextEvents(this.displayedCount);
        this.$store.dispatch('onReloadSensorFinished', this.sensorId);
      }
    },
    visible(value) {
      if (value) {
        this.$emit('becameVisible');
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import 'vuetify/src/styles/styles.sass'

.event-wrapper
  @media #{map-get($display-breakpoints, 'md-and-up')}
    // Keep a 4:3 aspect ratio
    height: calc((100vw / 4) * (3 / 4))

.event
  height: 100%
</style>
