var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "thumbnail-wrapper", attrs: { "data-testid": "thumbnail" } },
    [
      _c("event-thumbnail", {
        staticClass: "thumbnail",
        attrs: { event: _vm.event },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("event:selected")
          },
          loaded: function () {
            return (_vm.loaded = true)
          },
        },
      }),
      _vm._v(" "),
      _vm.hasVideo & _vm.loaded
        ? _c(
            "v-icon",
            {
              staticClass: "play-video-icon",
              attrs: { dark: "", "x-large": "", "data-testid": "has video" },
            },
            [_vm._v("\n    fa-play-circle\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "info-icon-list" },
        [
          _vm.event.pickup
            ? _c(
                "v-icon",
                {
                  staticClass: "info-icon",
                  attrs: { color: "primary", "data-testid": "pickup" },
                },
                [_vm._v("\n      fa-star\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.event.misdetection
            ? _c(
                "v-icon",
                {
                  staticClass: "info-icon fill-white--circle",
                  attrs: { color: "primary", "data-testid": "misdetection" },
                },
                [_vm._v("\n      fa-frown-o\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm._.isEmpty(_vm.event.tags)
            ? _c(
                "v-icon",
                {
                  staticClass: "info-icon",
                  attrs: { color: "primary", "data-testid": "has tags" },
                },
                [_vm._v("\n      fa-tag\n    ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          class: {
            "hide-button": true,
            "hide-button__hidden": _vm.event.hidden,
          },
          attrs: {
            icon: "",
            color: "primary",
            "data-testid": "visible",
            disabled: !_vm.editable,
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("event:hidden", !_vm.event.hidden)
            },
          },
        },
        [
          _c("v-icon", [
            _vm._v(_vm._s(_vm.event.hidden ? "fa-eye-slash" : "fa-eye")),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }