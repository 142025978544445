var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    {
      attrs: {
        "content-class": "overflow-x-hidden",
        fullscreen: "",
        "hide-overlay": "",
        persistent: "",
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _vm.visible
        ? _c(_vm.viewer, {
            tag: "component",
            attrs: {
              event: _vm.current,
              hasPrevious: _vm.index > 0,
              hasNext: _vm.index < _vm.events.length - 1,
              sensor: _vm.sensor,
            },
            on: {
              pollEvent: _vm.pollEvent,
              previous: function ($event) {
                return _vm.indexChanged(_vm.index - 1)
              },
              next: function ($event) {
                return _vm.indexChanged(_vm.index + 1)
              },
              deleteEvent: function () {
                _vm.deleteEventConfirmDialogVisible = true
              },
              close: _vm.close,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "thumbnails",
                  fn: function () {
                    return [
                      _c("thumbnail-slider", {
                        attrs: { items: _vm.events, keyName: "id" },
                        on: { scrolledToEnd: _vm.tryLoadNextEvents },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function (props) {
                                return [
                                  _c("event-thumbnail", {
                                    class: {
                                      active: _vm.index == props.index,
                                      "selectable-thumbnail": true,
                                    },
                                    attrs: { event: props.item },
                                    on: {
                                      click: function ($event) {
                                        _vm.index = props.index
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2964717497
                        ),
                        model: {
                          value: _vm.index,
                          callback: function ($$v) {
                            _vm.index = $$v
                          },
                          expression: "index",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1569488539
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c("confirm-dialog", {
        attrs: { message: _vm.$t("msg.delete_event_confirm") },
        on: { yes: _vm.deleteEvent },
        model: {
          value: _vm.deleteEventConfirmDialogVisible,
          callback: function ($$v) {
            _vm.deleteEventConfirmDialogVisible = $$v
          },
          expression: "deleteEventConfirmDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }