import { render, staticRenderFns } from "./EventThumbnail.vue?vue&type=template&id=695afd90&scoped=true&"
import script from "./EventThumbnail.vue?vue&type=script&lang=js&"
export * from "./EventThumbnail.vue?vue&type=script&lang=js&"
import style0 from "./EventThumbnail.vue?vue&type=style&index=0&id=695afd90&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "695afd90",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('695afd90')) {
      api.createRecord('695afd90', component.options)
    } else {
      api.reload('695afd90', component.options)
    }
    module.hot.accept("./EventThumbnail.vue?vue&type=template&id=695afd90&scoped=true&", function () {
      api.rerender('695afd90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/EventThumbnail.vue"
export default component.exports